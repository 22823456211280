import React from 'react';
import { errorCodes } from '../../mockdata';
import Container from '../Container/Container';
import Text from '../Text/Text';
import classes from './ErrorPage.module.scss';

export default function NotFoundPage({ statusCode }: { statusCode: number }) {
  return (
    <Container className={classes.container}>
      <Text className={classes.errorCode}>{statusCode}</Text>
      <Text>{errorCodes[statusCode as keyof typeof errorCodes] || errorCodes.default}</Text>
    </Container>
  );
}
