import { NextPageContext } from 'next';
import ErrorPageX from '../components/ErrorPage/ErrorPage';

export default function Error({ statusCode }: { statusCode: number }) {
  return <ErrorPageX statusCode={statusCode} />;
}

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
